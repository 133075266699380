(function( $ ) {

    $.fn.extend({
        disable: function(state) {
            return this.each(function() {
                this.disabled = state;
            });
        }
    });

    $.fn.formsubmit = function(options, callbacks) {
        if (typeof options === 'undefined') {
            options = {};
        }

        var formSelector = this.selector;
        var submitSelector = (options.submitSelector)? options.submitSelector : this.selector +' .js-save-button';
        var submitVal = jQuery(submitSelector).val();
        var submitHtml = jQuery(submitSelector).html();

        /**
         * Validates the given input form data
         *
         * @return boolean True if all required fields are filled
         */
        function validateInputFields() {
            var errorFlag = 0;
            var allInputTextFilled = true;
            var requiredSelector = options.selectors.required;

            jQuery('input[type="text"]'+ requiredSelector, formSelector).each(function() {
                inputVal = jQuery(this).val();

                if($.trim(inputVal) === ""){
                    allInputTextFilled = false;
                }
            });

            var allCheckboxesChecked = true;
            var groupsChecked = {};

            jQuery('input[type="radio"]'+ requiredSelector +', input[type="checkbox"]'+ requiredSelector, formSelector).each(function() {
                var group = jQuery(this).attr('name');

                if (groupsChecked[group] === undefined) {
                    if (jQuery('[name="'+ group +'"]:checked', formSelector).length === 0) {
                        allCheckboxesChecked = false;
                    }

                    groupsChecked[group] = true;
                }
            });

            if (allInputTextFilled === true && allCheckboxesChecked === true) {
                return true;
            }

            return false;
        }

        /**
         * The logic for what will happen when
         * an error returns from the submit.
         *
         * @param  array data The data retrieved from the ajax request
         * @return void
         */
        var submitError = function(data, message) {
            for (var field in data.errors) {
                jQuery('input[name="'+ field +'"]')
                    .closest('.field-container')
                    .addClass('error');
            }

            var errorMessage = message || options.message.error;

            jQuery(formSelector)
                .find(options.selectors.errorMessage)
                .hide()
                .html(errorMessage)
                .slideDown();

            callbacks.afterError(data);
        };

        /**
         * On submit success this function will be called
         *
         * @param  array data The data retrieved from the ajax request
         * @return void
         */
        var submitSuccess = function(data) {
            jQuery('html, body').animate({
                scrollTop: jQuery(formSelector).offset().top - 49
            }, 500);

            jQuery(formSelector)
                .hide()
                .html(options.message.success)
                .slideDown();

            callbacks.afterSuccess(data);
        };

        /**
         * Extracts the response data
         *
         * @param  {mixed}  data The response data
         * @return {object}      [description]
         */
        function extractResponseData(response) {
            switch (typeof(response)) {
                case 'boolean':
                case 'string':
                    response = $.parseJSON(response);
                    break;

                case 'object':
                    response = response;
                    break;

                default:
                    response = {
                        'errors': 'Unexcpected response'
                    };
                    break;
            }

            return response;
        }

        /**
         * Submits the given form data with ajax
         *
         * @param  string   formData The serialized form data
         * @return boolean           True if the form should submit as normal
         */
        function submitForm(formData, event) {
            jQuery(submitSelector).disable(true).val(options.message.loading).html(options.message.loading);

            var formActionUrl = $(event.target).attr('action');
            if ('' === formActionUrl) {
                formActionUrl = ajax.url +'?action='+ options.action;
            }

            formData = callbacks.filterFormData(formData, event);

            $.ajax({
                type: 'POST',
                url: formActionUrl,
                data: formData,
                success:function(response){
                    jQuery(submitSelector).disable(false).val(submitVal).html(submitHtml);

                    response = extractResponseData(response);

                    if (response.errors) {
                        callbacks.submitError(response, submitError, event);
                    } else {
                        callbacks.submitSuccess(response, submitSuccess, event);
                    }
                },
                error: function(response, status, code){
                    jQuery(submitSelector).disable(false).val(submitVal).html(submitHtml);

                    response = extractResponseData(
                        response.responseText
                    );

                    callbacks.submitError(response, submitError, event);
                }
            });
        }

        /**
         * The logic for submitting the process submit
         * button.
         *
         * @return boolean True will submit the form as normal
         */
        var processSubmit = function(event, element) {
            var formData = jQuery(event.target).serialize();

            if (callbacks.validateInputFields(event, element, jQuery(event.target))) {
                submitForm(formData, event);
            } else {
                jQuery(formSelector)
                    .find(options.selectors.errorMessage)
                    .hide()
                    .html(options.message.error)
                    .slideDown();
            }

            /**
             * Prevent the submit button to fire
             */
            return false;
        };

        /**
         * Monitors the submit button of the
         * given form.
         *
         * @return void
         */
        var monitorSubmit = function() {
            // Only starts monitoring when the
            // formselector actually exists
            if (jQuery(formSelector).length > 0) {
                jQuery(formSelector).on('submit', processSubmit);
            }
        };

        /**
         * Initializes the plugin
         * @return void
         */
        (function init() {
            monitorSubmit();
        })();

        var defaultOptions = {
            message : {
                error : 'Om dit formulier te kunnen versturen we alle bovenstaande gegevens van u nodig.',
                success : 'Bedankt! Uw aanvraag is succesvol verzonden.',
                loading : 'Laden...'
            },
            action : 'post_offer', // Make this your own
            selectors : {
                required : '.form-field-required',
                errorMessage : '.error-message, .js-error-message'
            }
        };

        var defaultCallbacks = {
            filterFormData : function(formData, event) {
                return formData;
            },
            submitError : function(data, coreFunction, event) {
                submitError(data);
            },
            submitSuccess : function(data, coreFunction, event) {
                submitSuccess(data);
            },
            validateInputFields : function() {
                return validateInputFields();
            },
            afterSuccess : function(data) {
                // your code
            },
            afterError : function(data) {
                // your code
            }
        };
        options = $.extend(true, defaultOptions, options);
        callbacks = $.extend(true, defaultCallbacks, callbacks);
    };
})(jQuery);