/**
 * The Quiz mechanism
 * @param {jQuery} $ The jQuery object
 */
function Quiz($)
{
    var self = this;

    /**
     * Set the AJAX URL from the variable on the page
     * @type {String}
     */
    self.ajaxUrl = window.adminAjaxUrl;

    /**
     * The radio buttons for the answers
     * @type {jQuery}
     */
    self.$answerButtons = $('.answer-choice');

    /**
     * Te button that starts the quiz
     * @type {jQuery}
     */
    self.$startButton = $('.quiz-start');

    /**
     * The element that shows the remaining seconds
     * @type {jQuery}
     */
    self.$startButtonTimer = self.$startButton.find('.quiz-start-timer');

    /**
     * The amount of seconds allowed for the quiz
     * @type {Number}
     */
    self.allowedTime = self.$startButton.data('allowedTime');

    /**
     * The time left starts as the total amount of seconds, and counts down
     * @type {Number}
     */
    self.timeLeft = self.allowedTime;

    /**
     * The interval for the countdown in the start button
     * @type {Interval}
     */
    self.countdownInterval = null;

    /**
     * All the question numbers, to check answered questions with
     * @type {Array}
     */
    self.allQuestionNumbers = self.$startButton.data('allQuestionNumbers');

    /**
     * The numbers of answered questions
     * @type {Array}
     */
    self.answeredQuestions = [];

    /**
     * The element showing the quiz results
     * @type {jQuery}
     */
    self.$quizResults = $('.section-quiz-results');

    /**
     * The template for showing the quiz results
     * @type {string}
     */
    self.$quizResultsTemplate = $('.section-quiz-results-template');

    /**
     * Elements to show or hide on quiz events
     * @type {Object}
     */
    self.$onQuizEvent = {
        start: {
            show: $('[data-on-quiz-start="show"]'),
            hide: $('[data-on-quiz-start="hide"]')
        },
        /*
        end: {
            show: $('[data-on-quiz-end="show"]'),
            hide: $('[data-on-quiz-end="hide"]')
        },
        */
        finish: {
            show: $('[data-on-quiz-finish="show"]'),
            hide: $('[data-on-quiz-finish="hide"]')
        }
    };

    /**
     * The score of the quiz, as a result from the finishing
     * @type {Object}
     */
    self.quizScore = null;

    /**
     * The highscores of the quiz, as a result from the finishing
     * @type {Array}
     */
    self.quizHighscores = null;

    /**
     * The quiz and highscore ranks of the current user
     * @type {Object}
     */
    self.ranks = {
        quiz: null,
        highscore: null
    };

    /**
     * The button that triggers the score showing
     * @type {jQuery}
     */
    self.$showScoreButton = $('.show-score');



    /**
     * Determine if the quiz is present on the page
     * Used for deciding if the script needs to be enabled or not
     * @return {Boolean}
     */
    self.isOnPage = function()
    {
        return ($('.section-quiz').length > 0);
    };



    /**
     * Output a message to the JavaScript console
     * @param  {Object} message
     * @return {void}
     */
    self.outputMessage = function(message)
    {
        if (message.status !== 200) {
            console.error(message);
        } else {
            console.log(message);
        }
    };



    /**
     * Send a start-quiz or end-quiz signal to the server
     * @param {String} type 'start' or 'end'
     * @param {Number} alleyId The alley page of the current quiz
     * @return {Promise}
     */
    self.setTime = function(type, alleyId)
    {
        return $.post(self.ajaxUrl, {
            action: 'set_quiz_time',
            type: type,
            alley_id: alleyId
        }, null, 'json');
    };



    /**
     * Store a question answer
     * @param {Number} alleyId The alley page of the current quiz
     * @param {Number} question The question number
     * @param {Number} question The answer number
     * @return {Promise}
     */
    self.setQuestionAnswer = function(alleyId, question, answer)
    {
        return $.post(self.ajaxUrl, {
            action: 'set_question_answer',
            alley_id: alleyId,
            question: question,
            answer: answer
        }, null, 'json');
    };



    /**
     * Finish a quiz
     * @param {Number} alleyId The alley page of the current quiz
     * @param {Number} question The question number
     * @return {Promise}
     */
    self.finishQuiz = function(alleyId)
    {
        return $.post(self.ajaxUrl, {
            action: 'finish_quiz',
            alley_id: alleyId
        }, null, 'json');
    };



    /**
     * Enable the button that starts the quiz
     * @return {void}
     */
    self.enableStartButton = function()
    {
        self.$startButton.on('click', function(event) {
            event.preventDefault();

            // Disable the button
            self.$startButton.prop('disabled', true);

            // Show/hide elements
            self.$onQuizEvent.start.show.slideDown(300);
            self.$onQuizEvent.start.hide.slideUp(300);

            // Register the start moment
            self.setTime('start', self.$startButton.data('alleyId'));

            // Start the countdown interval
            self.countdownInterval = setInterval(self.buttonCountdown, 1000);
        });
    };



    /**
     * Show a countdown in the start button
     * @return {void}
     */
    self.buttonCountdown = function()
    {
        // Update the seconds in the timer
        self.$startButtonTimer.text(--self.timeLeft);

        // Stop the interval when the time is depleted
        if (self.timeLeft <= 0) {
            clearTimeout(self.countdownInterval);
        }
    };



    /**
     * Enable the answer buttons mechanism
     * @return {void}
     */
    self.enableAnswerButtons = function()
    {
        // 'change' means an answer has been given
        self.$answerButtons.on('change', function(event) {
            var $changedButton = $(event.target);

            // Properties from data- attributes
            var alleyId = $changedButton.data('alleyId');
            var questionNumber = $changedButton.data('question');
            var answerNumber = $changedButton.data('answer');

            // Disable the answer buttons for the answered question
            var questionName = $changedButton.attr('name');
            $('[name="' + questionName + '"]').prop('disabled', true);

            // Register the question answer
            self.setQuestionAnswer(alleyId, questionNumber, answerNumber)
            .then(function(response) {
                // Show answer feedback, if that data is returned
                if (response !== undefined) {
                    if (response.data !== null && response.data.status === 'answered') {
                        var $allAnswers = $('[data-question="' + response.data.questionNumber + '"]');
                        var $correctAnswer = $allAnswers.filter('[data-answer="' + response.data.correctAnswer + '"]');
                        // var $givenAnswer = $allAnswers.filter('[data-answer="' + response.data.givenAnswer + '"]');

                        // Apply CSS classes to show if the answer is correct or not
                        $allAnswers.not($correctAnswer).next('label').addClass('incorrect');
                        $correctAnswer.next('label').addClass('correct');
                    }
                }

                self.answeredQuestions.push(questionNumber);

                // All questions are answered
                if (self.allQuestionsAnswered()) {
                    // Stop the timer
                    clearTimeout(self.countdownInterval);

                    // Set the end time
                    return self.setTime('end', alleyId);
                }
            })
            .then(function(response) {
                // If all questions are answered,
                // finish the quiz / calculate the score
                if (self.allQuestionsAnswered()) {
                    return self.finishQuiz(alleyId);
                }
            })
            .then(function(response) {
                if (response !== undefined) {
                    // Store the score data, if there is any
                    if (response.data !== null && response.data.status === 'finished') {
                        self.quizScore = response.data.score;
                        self.quizHighscores = response.data.highscores;
                        self.ranks = {
                            quiz: response.data.quizRank,
                            highscore: response.data.highscoreRank
                        };

                        /*
                        // Show/hide elements
                        self.$onQuizEvent.end.show.slideDown(300);
                        self.$onQuizEvent.end.hide.slideUp(300);
                        */
                        self.renderResults();
                    }
                }
            });
        });
    };



    /**
     * Enable the button that shows the score after finishing the quiz
     * @return {void}
     */
    self.enableShowScoreButton = function()
    {
        /*
        self.$showScoreButton.on('click', function(event) {
            event.preventDefault();
            self.renderResults();
        });
        */
    };



    /**
     * See if all the questions have been answered
     * @return {Boolean}
     */
    self.allQuestionsAnswered = function()
    {
        var allAnswered = true;

        // The value becomes false, if at least 1 question is not answered
        $.each(self.allQuestionNumbers, function(index, number) {
            if ($.inArray(number, self.answeredQuestions) < 0) {
                allAnswered = false;
            }
        });

        return allAnswered;
    };



    /**
     * Show the results of the quiz on the screen
     * @param  {object} response The response from the quiz finishing (having score data)
     * @return {void}
     */
    self.renderResults = function()
    {
        var scorePercentage = Math.round(self.quizScore.answered_correctly / self.quizScore.total_questions * 100);
        var content = self.$quizResultsTemplate.html();
        var $content;
        var isLoggedIn = self.$quizResultsTemplate.hasClass('logged-in');

        // Template handling for visitors (not logged in)
        if ( ! isLoggedIn) {
            // Replace placeholders
            content = content.replace(/\{answeredCorrectly\}/g, self.quizScore.answered_correctly)
                .replace(/\{totalQuestions\}/g, self.quizScore.total_questions);

            // Adjust elements
            $content = $(content);
            $content.find('.answer-score-bar').css('width', scorePercentage + '%');
            $content.find('.answer-score-pointer').css('left', scorePercentage + '%');

        }

        // Template handling for logged in users
        else {
            // Replace placeholders
            content = content.replace(/\{totalScore\}/g, self.quizScore.total_score)
                .replace(/\{timeTaken\}/g, self.quizScore.time_taken)
                .replace(/\{quizRank\}/g, self.ranks.quiz)
                .replace(/\{answeredCorrectly\}/g, self.quizScore.answered_correctly)
                .replace(/\{totalQuestions\}/g, self.quizScore.total_questions);

            // Replace the highscore ranking if it's not null
            if (self.ranks.highscore !== null) {
                content = content.replace(/\{highscoreRank\}/g, self.ranks.highscore);
            }

            $content = $(content);

            // Show the highscore rank elements, if there is a highscore rank
            if (self.ranks.highscore !== null) {
                $content.find('.highscore-rank-wrapper').removeClass('d-none');
            }

            // The element holding the highscore items
            var $highscoresContainer = $content.find('.quiz-result-highscores');

            // Prepare the highscore items
            var highscoreItemTemplate = $content.find('.quiz-result-highscore-item').html();
            $.each(self.quizHighscores, function(index, highscore) {
                // Replace placeholders
                var itemTemplate = highscoreItemTemplate.replace(/\{hsDisplayName\}/g, highscore.display_name)
                    .replace(/\{hsAnsweredCorrectly\}/g, highscore.answered_correctly)
                    .replace(/\{hsTotalQuestions\}/g, highscore.total_questions)
                    .replace(/\{hsTimeTaken\}/g, highscore.time_taken);

                // Append to the highscores container
                $(itemTemplate).appendTo($highscoresContainer);
            });
        }

        if (scorePercentage >= 75) {

            $content.find('.answer-score-compliment').removeClass('d-none');
        }

        // Update the results section
        self.$quizResults.html($content);

        // Show/hide elements
        self.$onQuizEvent.finish.show.slideDown(300);
        self.$onQuizEvent.finish.hide.slideUp(300);
    };
}
