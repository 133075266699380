/**
 * General page scripts
 * @param {jQuery} $ The jQuery object
 */
function General($)
{
    var self = this;

    /**
     * The body that will scroll
     * @type {jQuery}
     */
    self.$scrollBody = $('html, body');

    /**
     * The selector for the elements that scroll the page
     * @type {String}
     */
    self.selector = '[data-scroll-to]';

    /**
     * The amount of pixels above the 'scroll to' target(s)
     * @type {Number}
     */
    self.scrollHeadroom = 30;

    /**
     * The amount of milliseconds the scrolling takes
     * @type {Number}
     */
    self.scrollTime = 300;



    /**
     * Enable the page scrolling by element
     * @return {void}
     */
    self.enableScrollLinks = function()
    {
        // Delegated event, because of possible DOM changes
        $('body').on('click', self.selector, function(event) {
            event.preventDefault();

            // Get the target to scroll to, from the data property
            // Find on every click, because elements can be added/changed
            var $target = $($(event.target).closest(self.selector).data('scrollTo'));

            // Scroll to the target, if it exists
            if ($target.length > 0) {
                self.$scrollBody.animate({
                    scrollTop: $target.offset().top - self.scrollHeadroom
                }, self.scrollTime);
            }
        });
    };
}
