jQuery(function(){
    jQuery('[data-toggle=popover]').popover({
        html: true,
        content: function() {
            var content = jQuery(this).attr('data-popover-content');
            return jQuery(content).children('.popover-body').html();
          },
        title: function() {
            var title = jQuery(this).attr('data-popover-content');
            return jQuery(title).children('.popover-heading').html();
          }
      });

    jQuery('html').on('click', function(e) {
        if (typeof jQuery(e.target).data('original-title') === 'undefined' &&
            !jQuery(e.target).parents().is('.popover')) {
          jQuery('[data-original-title]').popover('hide');
        }
      });

    jQuery( '#show-register-form' ).click(function( event ) {
        jQuery('#account-register').removeClass('d-none');
        jQuery('#account-login').addClass('d-none');
    });

    jQuery( '#show-login-form' ).click(function( event ) {
        jQuery('#account-register').addClass('d-none');
        jQuery('#account-login').removeClass('d-none');
    });
  });